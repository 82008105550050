<template>
  <div>
    <!--    -->
    <div id="container" ></div>
  </div>
</template>

<script>
import tools from "@/common/js/tools";
import {getLocationAddress,getLocation} from "@/api/common";
import {bdToTx} from "@/api/index";
import {Toast} from "vant";

export default {
  name: "tx-map",
  components: {},
  data() {
    return {
      longitude: 0, //经度
      latitude: 0, //纬度
      city: "",
    };
  },
  async mounted() {
    this.getServerLocation();
    // this.getBdServerLocation();
  },
  methods: {
    getBdServerLocation(){
      // let map = new BMapGL.Map("container");
      // let point = new BMapGL.Point(116.331398,39.897445);
      // map.centerAndZoom(point,12);
      let geolocation = new BMapGL.Geolocation();
      geolocation.enableSDKLocation();
      let _that=this;
      geolocation.getCurrentPosition(function(r){
        console.log(r)
        if(this.getStatus() == BMAP_STATUS_SUCCESS){
          console.log(r.point)
          _that.bdToTx(r.point);
        }
        else {
          console.log(this.getStatus())
          Toast.fail("信息定位失败");
        }
      });
    },
    async bdToTx(point){
      let ret = await bdToTx({'type':3,'key':'VDEBZ-O22HK-DHHJU-AXDG5-AJGDZ-WXFEJ','locations':point.lat+','+point.lng})
      let locations=ret.locations;
      if(ret.status!==undefined && ret.status===0){
        // this.$emit("setLocation", ret.locations[0]);
        let ret = await getLocationAddress({'lat' :locations[0].lat,'lng':locations[0].lng});
        if(ret.code*1===1){
          this.$emit("setLocation", ret.data);
        }
      }else {
        Toast.fail("信息定位失败");
      }
    },
    async getServerLocation(){
      let isWx=tools.isWx();
      if(isWx){
        this.getWxMyLocation();
      }else {
        this.getMyLocation();
      }
      // let ret = await getLocation();
      // if(ret.code*1===1){
      //   this.$emit("setLocation", ret.data);
      // }else {
      //   let isWx=tools.isWx();
      //   if(isWx){
      //     this.getWxMyLocation();
      //   }else {
      //     this.getMyLocation();
      //   }
      // }
    },
    getWxMyLocation(){
      tools.wxInitialize([
        "getLocation"
      ]);
      let _that=this;
      wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
        wx.getLocation({
          type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: async function (res) {
            let latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
            let longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
            let ret = await getLocationAddress({'lat' :latitude,'lng':longitude});
            if(ret.code*1===1){
              _that.$emit("setLocation", ret.data);
            }
          }
        });

      });

    },
    getMyLocation() {
      //第一部分
      //定位获得当前位置信息
      var geolocation = new qq.maps.Geolocation(
        "VDEBZ-O22HK-DHHJU-AXDG5-AJGDZ-WXFEJ",
        "zhapp"
      );
      geolocation.getIpLocation(this.showPosition, this.showErr);
      // geolocation.getLocation(this.showPosition, this.showErr);//或者用getLocation精确度比较高
    },
    async showPosition(position) {
      //成功回调
      // alert(JSON.stringify(position))
      this.latitude = position.lat;
      this.longitude = position.lng;
      this.city = position.city;
      let ret = await getLocationAddress({'lat' :position.lat,'lng':position.lng});
      if(ret.code*1===1){
        this.$emit("setLocation", ret.data);
      }
      // this.setMap();
    },
    showErr() {
      //失败回调
      this.getMyLocation(); //定位失败再请求定位，测试使用
    },

    setMap() {
      //画地图
      //设置地图中心点
      var myLatlng = new qq.maps.LatLng(this.latitude, this.longitude);
      //定义工厂模式函数
      var myOptions = {
        zoom: 13, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
        mapTypeId: qq.maps.MapTypeId.ROADMAP, //设置地图样式详情参见MapType
      };
      // //获取dom元素添加地图信息
      var map = new qq.maps.Map(
        document.getElementById("container"),
        myOptions
      );
      //第三部分
      //给定位的位置添加图片标注
      var marker = new qq.maps.Marker({
        position: myLatlng,
        map: map,
      });
      //给定位的位置添加文本标注
      var marker = new qq.maps.Label({
        position: myLatlng,
        map: map,
        content: "这是我当前的位置，偏差有点大，哈哈",
      });
    },

    gdMap() {
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: "RB",
        });

        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);

        function onComplete(data) {
          // data是具体的定位信息
          // alert(JSON.stringify(data));
        }

        function onError(data) {
          // 定位出错
          // alert(JSON.stringify(data));
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#container {
  width: 100%;
}
</style>
