<template>
  <div>
    <div class="nav-bar">
      <van-icon name="arrow-left" class="nav-icon" @click="$router.go(-1)" />
      <span>{{ shopInfo.type_name }}入驻</span>
    </div>
    <div class="bind-info">
      <h3 v-if="shopInfo.type === 5">店铺信息</h3>
      <van-form>
        <!-- 表单根据实际情况判断是否需要展示，店铺信息-和-地址信息 -->
        <div class="form-card" v-if="shopInfo.type === 5">
          <van-field
            v-model="shopName"
            name="店铺名称"
            label="店铺名称"
            placeholder="输入店铺名称"
          />
          <van-field
            name="店铺分类"
            label="店铺分类"
            readonly
            clickable
            :value="className"
            placeholder="点击选择店铺分类"
            @click="showClass = true"
          />
          <van-field
            v-model="permitCode"
            name="营业执照编码"
            label="营业执照编码"
            placeholder="输入营业执照编码"
          />
          <van-cell
            title="让利比例"
            is-link
            :value="discount + '%'"
            @click="showHumility = true"
          />

          <van-field
            v-model="y_turnover"
            name="预计营业额(元)"
            label="预计营业额(元)"
            placeholder="请输入预计营业额(元)"
          />
        </div>
        <div class="form-card">
          <h3>地址信息</h3>
          <van-field
            readonly
            clickable
            name="area"
            :value="city_names"
            label="地区选择"
            placeholder="点击选择省市区"
            @click="showPopup = true"
          >
            <template #right-icon>
              <van-icon name="arrow" />
            </template>
          </van-field>
          <van-field
            v-if="shopInfo.type === 5"
            v-model="detailAddress"
            type="text"
            name="详细地址"
            label="详细地址"
            right-icon="location-o"
            placeholder="输入详细地址"
            @focus="addressFocus"
            @click-right-icon="clickRightIcon"
          >
            <template #right-icon>
              <van-icon name="location-o" color="#AAAAAA" size="20" />
            </template>
          </van-field>
        </div>
        <div class="form-card">
          <h3>个人资料</h3>
          <van-field
            v-model="userName"
            name="姓名"
            label="姓名"
            placeholder="输入姓名"
          />
          <van-field
            v-model="idCode"
            name="身份证"
            label="身份证"
            placeholder="输入身份证"
          />
          <van-field
            v-model="phone"
            name="手机号"
            label="手机号"
            type="number"
            placeholder="输入手机号"
          />
          <van-field
            v-if="shopInfo.type === 5"
            v-model="password"
            name="登陆密码"
            label="登陆密码"
            type="password"
            placeholder="输入登陆密码"
          />
          <van-field
            v-model="verifyCode"
            center
            clearable
            label="短信验证码"
            placeholder="请输入短信验证码"
            @clear="clearVerifyCode"
          >
            <template #button>
              <van-button
                size="small"
                @click="sendCode"
                @click.stop
                v-if="showTime"
              >
                获取验证码
              </van-button>
            </template>
            <template #extra>
              <van-count-down :time="time" format="ss" v-if="!showTime">
                <template #default="timeData">
                  <span class="block-time">{{ timeData.seconds }}</span>
                  <span class="colon">秒</span>
                </template>
              </van-count-down>
            </template>
          </van-field>
        </div>
        <div class="submit-button">
          <van-button round block type="danger" @click="onSubmit">
            绑定信息
          </van-button>
        </div>
      </van-form>
    </div>
    <!-- 弹出层地址选择器 -->
    <van-popup v-model="showPopup" round position="bottom">
      <!-- 联级选择 -->
      <van-cascader
        v-model="city_names"
        :title="
          '请选择区域' +
          (recommendCity === '' ? '' : '（' + recommendCity + ')')
        "
        :options="options"
        :field-names="fieldNames"
        @finish="chooseComplete"
        @close="onClose"
      />
    </van-popup>
    <!-- 弹出层地址选择器 -->
    <van-popup v-model="showClass" round position="bottom">
      <!-- 联级选择 -->
      <van-cascader
        title="请选择店铺分类"
        :options="shopClass"
        :field-names="fieldClassNames"
        @finish="chooseClassComplete"
        @close="closeClass"
      />
    </van-popup>
    <van-overlay :show="showOverlay">
      <div class="wrapper" @click.stop="getBack">
        <div class="block">
          <!-- 根据不同的情况展示是否允许入驻 -->
          <div class="enter-apply" v-if="!isError">
            <h3>{{ shopInfo.type_name }}</h3>
            <p>邀请人-{{ shopInfo.shop_name }}</p>
            <div class="deal">
              <van-checkbox v-model="checked" checked-color="#ee0a24" :label-disabled='true'>
                <span>我已阅读并同意</span>
                <span style="color: #f25c30" @click.stop="goToUrl">
                  振合网平台服务协议、
                </span>
                <span style="color: #f25c30" @click.stop @click="privacy">
                  振合网平台隐私声明
                </span>
                <!-- <a style="color: #f25c30" @click.stop>
                  <span> </span>
                </a>
                <a style="color: #f25c30" @click.stop @click="privacy"> </a> -->
              </van-checkbox>

            </div>
            <div class="button-box" @click="setAffirm">
              <img src="~@/assets/common/next_button@2x.png" alt="" />
            </div>
          </div>
          <div class="info-error" v-else>
            <img src="~@/assets/common/trading_closed_icon@2x.png" alt="" />
            <span class="span-info">{{
              errorMsg === "" ? "店铺信息错误" : errorMsg
            }}</span>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 让利遮罩层 -->
    <van-popup v-model="showHumility" class="vant-popup">
      <div class="block-box">
        <van-radio-group
          v-model="radio"
          v-for="(item, index) in 99"
          :key="index"
        >
          <van-radio
            :name="index"
            checked-color="#ee0a24"
            class="vant-radio"
            @click="clickRadio(item)"
          >
            让利比例 - {{ item }}%
          </van-radio>
        </van-radio-group>
      </div>
    </van-popup>
    <TxMap ref="txMap" @setLocation="setLocation"></TxMap>
    <MapSearch
      v-if="showMap"
      :lat="lat"
      :lon="lon"
      :cityName="city_names"
      @setDetailAddress="setDetailAddress"
      @endSearch="endSearch"
      class="map-search"
    ></MapSearch>
  </div>
</template>

<script>

  import axios from 'axios';
import {
  getShopClass,
  getShopCode,
  registerShops,
  getShopCityList,
} from "@/api/shop";
import { Toast } from "vant";
import regionFour from "@/common/region/regionFour.json";
import { commonSend } from "@/api/common";
import MapSearch from "@/components/map/mapSearch";
import TxMap from "@/components/map/tx-map";

export default {
  components: {
    MapSearch,
    TxMap,
  },
  data() {
    return {
      y_turnover:'',//预计营业额

      radio: 1, //单选框匹配标识
      options: regionFour,
      showPopup: false, // 弹出层
      showOverlay: true, // 遮罩层
      showHumility: false, // 让利弹出层
      checked: true, // 控制协议确认按钮
      time: 0, //倒计时
      showTime: true, // 时候开启倒计时
      shopName: "", //店铺名称
      permitCode: "", //营业执照码
      userName: "", //用户名
      idCode: "", //身份证码
      phone: "", //电话
      verifyCode: "", //短信验证码
      cascaderValue: "", //联级选择地址
      detailAddress: "", //详细地址
      fieldNames: {}, //自定义 options 结构中的字段
      shopCode:
        this.$route.query.shopCode === undefined
          ? 0
          : this.$route.query.shopCode,
      shopInfo: { id: 0 },
      province: 0,
      city: 0,
      area: 0,
      street: 0,
      lon: 106.55034,
      lat: 29.56208,
      city_names: "",
      shopClass: [],
      classId: "",
      password: "",
      classTwoId: "",
      className: "",
      showClass: false,
      fieldClassNames: {
        text: "text",
        value: "id",
        children: "children",
      },
      showMap: false,
      isError: false,
      errorMsg: "",
      recommendCity: "",
      discount: 3,
      flag: false,

      regionOneList:[],
      regionThreeList:[],
      regionFourList:[],
    };
  },
  mounted() {
    axios.get('regionOne.json').then((res)=>{
        this.regionOneList = res.data
    })
    axios.get('regionThree.json').then((res)=>{
        this.regionThreeList = res.data
    })
    axios.get('https://jhvideo.oss-cn-hangzhou.aliyuncs.com/app/json/regionFour.json',).then((res)=>{
        this.regionFourList = res.data
    })

    this.getShopCode();
    this.getShopClass();
  },
  methods: {
    closeClass() {
      this.showClass = false;
    },
    onClose() {
      this.showPopup = false;
    },
    clickRightIcon() {
      this.showMap = true;
    },
    setLocation(location) {
      this.detailAddress = location.addr;
      this.lat = location.lat;
      this.lon = location.lng;
    },
    async getShopCityList() {
      const res = await getShopCityList({ shop_code: this.shopCode });
      if (res.code * 1 === 1) {
        // this.options = res.data.city_list;
        // this.recommendCity = res.data.city_name;
        if(res.data.type === 0){
          this.options = this.regionOneList
        }else if(res.data.type === 1 || res.data.type === 3 || res.data.type === 2){
          this.options = this.regionThreeList
        }else{
          this.options = this.regionFourList
        }
      }
    },
    getBack() {
      if (this.isError) {
        this.$router.go(-1);
      }
    },
    clickRadio(discount) {
      // 点击单选框
      this.showHumility = false;
      this.discount = discount;
    },
    goToUrl() {
      this.$router.push({
        name: "agreement",
        query: { levelId: this.shopInfo.type },
      });
    },
    privacy() {
      this.$router.push({
        name: "privacy",
        query: { levelId: this.shopInfo.type },
      });
    },
    async getShopCode() {
      const res = await getShopCode({ shop_code: this.shopCode });
      if (res.code * 1 === 1) {
        this.shopInfo = res.data;
        if (this.shopInfo.type * 1 <= 5) {
          this.getShopCityList();
        }
      } else {
        this.isError = true;
        this.errorMsg = res.msg;
      }
    },
    clearVerifyCode() {
      this.verifyCode = "";
    },
    setAffirm() {
      if (this.checked === false) {
        Toast.fail("请阅读并同意入驻协议");
        return false;
      }
      this.showOverlay = false;
    },
    async sendCode() {
      if (this.phone === "") {
        Toast.fail("请输入手机号码");
        return false;
      }
      const res = await commonSend({
        phone: this.phone,
        send_type: "shop_register",
      });
      if (res.code * 1 == 1) {
        this.showTime = false;
        Toast.success(res.msg);
        this.time = 60000;
        const that = this;
        setInterval(() => {
          that.showTime = true;
        }, that.time);
      } else {
        Toast.fail(res.msg);
      }
    },

    chooseComplete(value) {
      // 地址全部选择完成时需要做什么
      this.province = value.selectedOptions[0].value;//省

      if(this.shopInfo.type === 1){
        this.city_names = value.selectedOptions[0].text
      }else if(this.shopInfo.type === 5){
        this.city = value.selectedOptions[1].value;//市
        this.area = value.selectedOptions[2].value; //区
        this.street = value.selectedOptions[3].value;//街道
        this.city_names =
          value.selectedOptions[0].text +
          " " +
          value.selectedOptions[1].text +
          " " +
          value.selectedOptions[2].text +
          " " +
          value.selectedOptions[3].text;
      }else{
        this.city = value.selectedOptions[1].value;//市
        this.area = value.selectedOptions[2].value; //区
        this.city_names =
          value.selectedOptions[0].text +
          " " +
          value.selectedOptions[1].text +
          " " +
          value.selectedOptions[2].text
      }
      this.showPopup = false;
    },
    async getShopClass() {
      const res = await getShopClass();
      if (res.code * 1 === 1) {
        this.shopClass = res.data;
      }
    },
    chooseClassComplete(value) {
      this.classId = value.selectedOptions[0].id;
      this.classTwoId = value.selectedOptions[1].id;
      this.className =
        value.selectedOptions[0].text + " " + value.selectedOptions[1].text;
      this.showClass = false;
    },
    addressFocus() {
      if (this.street_id <= 0) {
        Toast.fail("请选区域");
        return false;
      }
      if (this.detailAddress === "") {
        this.showMap = true;
      }
    },
    // showTxMap() {
    //   if (this.street_id <= 0) {
    //     Toast.fail("请选区域");
    //     return false;
    //   }

    //   // this.showMap = true;
    // },
    endSearch() {
      this.showMap = false;
    },
    setDetailAddress(location) {
      // this.detailAddress=location.poiaddress+' '+location.poiname;
      this.detailAddress = location.poiname;
      this.lon = location.latlng.lng;
      this.lat = location.latlng.lat;
      this.showMap = false;
    },
    onBlur() {
      // 身份证正则验证
      const reg =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      const regPhone = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (!regPhone.test(this.phone)) {
        Toast.fail("请输入正确的手机");
        return false;
      } else if (this.idCode === "") {
        Toast.fail("身份证不能为空");
        return false;
      } else if (!reg.test(this.idCode)) {
        Toast.fail("身份证格式错误");
        return false;
      }
      return true;
    },
    async onSubmit() {
      // if (this.shopName.length <= 0 || this.shopName.length > 10) {
      //   Toast.fail("店铺名称请输入1-10个字符");
      //   return false;
      // }
      if(this.shopInfo.type === 5&&!this.y_turnover){
        return Toast.fail("请输入预计营业额");
      }
      if (this.shopInfo.type === 5&&this.y_turnover < 0) {
        return Toast.fail("请输入预计营业额");
      }


      if (this.onBlur()) {
        if (this.flag) {
          return false;
        }
        this.flag = true;
        const res = await registerShops({
          shop_name: this.shopName,
          shopkeeper: this.userName,
          address: this.detailAddress,
          lon: this.lon,
          lat: this.lat,
          invite_code: this.shopCode,
          province_id: this.province,
          city_id: this.city,
          area_id: this.area,
          street_id: this.street,
          type: this.shopInfo.type,
          shop_phone: this.phone,
          license_sn: this.permitCode,
          class_two_id: this.classTwoId,
          class_id: this.classId,
          password: this.password,
          code: this.verifyCode,
          discount: this.discount,
          idCode: this.idCode,
          y_turnover:this.y_turnover,


        });
        if (res.code * 1 === 1) {
          Toast.success("提交成功，请等待审核...");
          let _that = this;
          setTimeout(function () {
            _that.$router.push({ name: "my" });
          }, 2000);
        } else {
          Toast.fail(res.msg);
          this.flag = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  position: relative;
  text-align: center;
  padding: 12px 0;
  font-size: 16px;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;

  .nav-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.map-search {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.bind-info {
  h3 {
    margin: 0;
    padding: 12px;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
  }

  .form-card {
    margin-bottom: 12px;
  }

  /deep/ .van-cell__title {
    font-weight: 600;
    color: #000;
  }

  .submit-button {
    padding: 0 12px;
  }
  .van-button--default {
    border: none;
    font-weight: 600;
  }
  /deep/ .van-count-down {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 78px;
    height: 33px;
  }
}

// 遮罩层
.van-overlay {
  background-color: #000;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  position: relative;
  width: 336px;
  height: 382px;
  background: url("~@/assets/common/background_of_settlement_agreement@2x.png")
    no-repeat center/ 100%;
  .info-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    img {
      display: block;
      width: 84px;
      height: 84px;
    }
    .span-info {
      padding-top: 10px;
      font-size: 16px;
      color: #e08809;
    }
  }
  .enter-apply {
    width: 72%;
    height: 50%;
    position: absolute;
    left: 48px;
    top: 130px;
    color: #ae813a;

    .button-box {
      width: 154px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        display: block;
      }
    }
  }

  .deal {
    display: flex;
  }
}

/deep/ .van-checkbox__label {
  color: #ae813a;
}
// 让利遮罩
.block-box {
  width: 336px;
  height: 454px;
  overflow: auto;
  font-size: 16px;
  padding: 8px 20px;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
}
.vant-popup {
  border-radius: 16px;
}
.vant-radio {
  padding: 12px 0;
}
/deep/.van-radio__label {
  margin-left: 20px;
}
</style>
